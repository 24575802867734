/* Sample header skins */
.skin-1 .main-panel > .header {
  color: rgba(255, 255, 255, 0.7);
  background-color: #7fc35c; }
  .skin-1 .main-panel > .header .navbar-nav .nav-link.dropdown > a,
  .skin-1 .main-panel > .header .nav > li > a,
  .skin-1 .main-panel > .header .navbar-heading,
  .skin-1 .main-panel > .header .material-icons {
    color: rgba(255, 255, 255, 0.7); }
  .skin-1 .main-panel > .header .navbar-nav .dropdown-menu {
    border-color: #7fc35c; }
  .skin-1 .main-panel > .header .search-form > input:-moz-placeholder {
    color: rgba(255, 255, 255, 0.7); }
  .skin-1 .main-panel > .header .search-form > input::-moz-placeholder {
    color: rgba(255, 255, 255, 0.7); }
  .skin-1 .main-panel > .header .search-form > input:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.7); }
  .skin-1 .main-panel > .header .search-form > input::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.7); }
  .skin-1 .main-panel > .header .search-form .form-control:focus {
    background: #7fc35c; }
  .skin-1 .main-panel > .header .nav > li > a:hover,
  .skin-1 .main-panel > .header .nav > li > a:focus,
  .skin-1 .main-panel > .header .nav .open > a,
  .skin-1 .main-panel > .header .nav .open > a:hover,
  .skin-1 .main-panel > .header .nav .open > a:focus {
    color: white; }

.skin-2 .main-panel > .header {
  color: rgba(255, 255, 255, 0.7);
  background-color: #4cc3f0; }
  .skin-2 .main-panel > .header .navbar-nav .nav-link.dropdown > a,
  .skin-2 .main-panel > .header .nav > li > a,
  .skin-2 .main-panel > .header .navbar-heading,
  .skin-2 .main-panel > .header .material-icons {
    color: rgba(255, 255, 255, 0.7); }
  .skin-2 .main-panel > .header .navbar-nav .dropdown-menu {
    border-color: #4cc3f0; }
  .skin-2 .main-panel > .header .search-form > input:-moz-placeholder {
    color: rgba(255, 255, 255, 0.7); }
  .skin-2 .main-panel > .header .search-form > input::-moz-placeholder {
    color: rgba(255, 255, 255, 0.7); }
  .skin-2 .main-panel > .header .search-form > input:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.7); }
  .skin-2 .main-panel > .header .search-form > input::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.7); }
  .skin-2 .main-panel > .header .search-form .form-control:focus {
    background: #4cc3f0; }
  .skin-2 .main-panel > .header .nav > li > a:hover,
  .skin-2 .main-panel > .header .nav > li > a:focus,
  .skin-2 .main-panel > .header .nav .open > a,
  .skin-2 .main-panel > .header .nav .open > a:hover,
  .skin-2 .main-panel > .header .nav .open > a:focus {
    color: white; }

.skin-3 .main-panel > .header {
  color: rgba(255, 255, 255, 0.7);
  background-color: #f0c54c; }
  .skin-3 .main-panel > .header .navbar-nav .nav-link.dropdown > a,
  .skin-3 .main-panel > .header .nav > li > a,
  .skin-3 .main-panel > .header .navbar-heading,
  .skin-3 .main-panel > .header .material-icons {
    color: rgba(255, 255, 255, 0.7); }
  .skin-3 .main-panel > .header .navbar-nav .dropdown-menu {
    border-color: #f0c54c; }
  .skin-3 .main-panel > .header .search-form > input:-moz-placeholder {
    color: rgba(255, 255, 255, 0.7); }
  .skin-3 .main-panel > .header .search-form > input::-moz-placeholder {
    color: rgba(255, 255, 255, 0.7); }
  .skin-3 .main-panel > .header .search-form > input:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.7); }
  .skin-3 .main-panel > .header .search-form > input::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.7); }
  .skin-3 .main-panel > .header .search-form .form-control:focus {
    background: #f0c54c; }
  .skin-3 .main-panel > .header .nav > li > a:hover,
  .skin-3 .main-panel > .header .nav > li > a:focus,
  .skin-3 .main-panel > .header .nav .open > a,
  .skin-3 .main-panel > .header .nav .open > a:hover,
  .skin-3 .main-panel > .header .nav .open > a:focus {
    color: white; }

.skin-4 .main-panel > .header {
  color: rgba(0, 0, 0, 0.7);
  background-color: white; }
  .skin-4 .main-panel > .header .navbar-nav .nav-link.dropdown > a,
  .skin-4 .main-panel > .header .nav > li > a,
  .skin-4 .main-panel > .header .navbar-heading,
  .skin-4 .main-panel > .header .material-icons {
    color: rgba(0, 0, 0, 0.7); }
  .skin-4 .main-panel > .header .navbar-nav .dropdown-menu {
    border-color: white; }
  .skin-4 .main-panel > .header .search-form > input:-moz-placeholder {
    color: rgba(0, 0, 0, 0.7); }
  .skin-4 .main-panel > .header .search-form > input::-moz-placeholder {
    color: rgba(0, 0, 0, 0.7); }
  .skin-4 .main-panel > .header .search-form > input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.7); }
  .skin-4 .main-panel > .header .search-form > input::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 0.7); }
  .skin-4 .main-panel > .header .search-form .form-control:focus {
    background: white; }
  .skin-4 .main-panel > .header .nav > li > a:hover,
  .skin-4 .main-panel > .header .nav > li > a:focus,
  .skin-4 .main-panel > .header .nav .open > a,
  .skin-4 .main-panel > .header .nav .open > a:hover,
  .skin-4 .main-panel > .header .nav .open > a:focus {
    color: #000; }

.skin-4 .sidebar-panel {
  background-color: #535a6c; }
  .skin-4 .sidebar-panel > .brand {
    background-color: #535a6c; }
    .skin-4 .sidebar-panel > .brand > a {
      color: rgba(255, 255, 255, 0.7); }
      .skin-4 .sidebar-panel > .brand > a:focus, .skin-4 .sidebar-panel > .brand > a:hover {
        color: rgba(255, 255, 255, 0.7); }
  .skin-4 .sidebar-panel .nav-profile > .dropdown-toggle,
  .skin-4 .sidebar-panel .nav-title {
    color: rgba(255, 255, 255, 0.8); }
  .skin-4 .sidebar-panel > nav > ul > li > a:hover,
  .skin-4 .sidebar-panel > nav > ul > li.open > a:hover {
    background-color: #4c5363; }
  .skin-4 .sidebar-panel > nav li.open > a,
  .skin-4 .sidebar-panel > nav li.active > a {
    color: #fff; }
  .skin-4 .sidebar-panel > nav > ul > li.open:before {
    position: absolute;
    z-index: 99;
    top: 0;
    width: 4px;
    height: 100%;
    content: '';
    background: #4c7ff0;
    left: 0; }
  .skin-4 .sidebar-panel > nav a {
    color: rgba(255, 255, 255, 0.8); }
    .skin-4 .sidebar-panel > nav a:focus, .skin-4 .sidebar-panel > nav a:hover {
      color: #fff !important; }

.skin-4 .sidebar-panel {
  box-shadow: none; }